import { FetchError } from 'ofetch'
/* import i18n from '#i18n' */

export const useApiError = (error: FetchError<any> | null) => {
  if (error) {
    showError({
      statusCode: error.response?.status || error.statusCode || 500,
      message: error.data?.message || error.message || 'Error!'
    })
  }
}

export const useDomainHeader = () => {
  const { host, layout } = useDomainHost()

  if (process.env.NODE_ENV === 'development') {
    return layout === 'platform' ? 'dev.mevcut.co' : useDomain()
  } else {
    return host
  }
}

function queryLocale(query: any) {
  return {
    app_locale: useNuxtApp().$i18n?.locale?.value,
    ...(query || {})
  }
}

export const useBasicFetch: typeof useFetch = (path, opts?) => {
  let url = path as string
  if (!url.startsWith('/nuxt-api')) {
    url = useBaseUrl(path as string)
  }
  return useFetch(url, {
    ...(opts || {}),

    query: queryLocale(opts?.query),

    headers: {
      ...(opts?.headers || {}), // In case of authorization, content-type, etc.
      Accept: 'application/json',
      domain: useDomainHeader()
    }
  })
}
