import { default as _91id_93xCfPJT7YfVMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/about/[id].vue?macro=true";
import { default as cartIr9db7PFOJMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/cart.vue?macro=true";
import { default as categoriesbTyfE44y2mMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/categories.vue?macro=true";
import { default as indexkTOT2CqwqiMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/index.vue?macro=true";
import { default as _91id_93OK31xbfh6pMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/materials/[id].vue?macro=true";
import { default as indexOfazRLc6BgMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/materials/index.vue?macro=true";
import { default as offers92h3dUwBIrMeta } from "/opt/atlassian/pipelines/agent/build/pages/domain/offers.vue?macro=true";
import { default as about_45uslQ3PoIjEnRMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/about-us.vue?macro=true";
import { default as email_45reset_45passwordOEAbhh4mZMMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/email-reset-password.vue?macro=true";
import { default as forgot5zCEC2nkwYMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/forgot.vue?macro=true";
import { default as loginE8pgO8gXgqMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/login.vue?macro=true";
import { default as phone_45reset_45passwordiSI0caNjS6Meta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/phone-reset-password.vue?macro=true";
import { default as registeryG2D0wQyoZMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/register.vue?macro=true";
import { default as indexPFK5OV6vSfMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/index.vue?macro=true";
import { default as _providermYWFa5Q6BSMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/social/_provider.vue?macro=true";
import { default as verify_45emailZknSiJ9JAjMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/auth/verify-email.vue?macro=true";
import { default as cartTNrhAGwQsMMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/cart.vue?macro=true";
import { default as indexddtTUTcohUMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/delivering/index.vue?macro=true";
import { default as _91id_93yM6dxNI8gXMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/delivering/materials/[id].vue?macro=true";
import { default as _91id_93LWWvpTztgDMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/delivering/stores/[id].vue?macro=true";
import { default as _91id_93oS2vjo2KgZMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/delivering/storeTypes/[id].vue?macro=true";
import { default as indexxvIP0ZaBljMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/index.vue?macro=true";
import { default as _91id_93j0g6Sp9UXhMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/materials/[id].vue?macro=true";
import { default as indexktxubpk7BQMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/materials/index.vue?macro=true";
import { default as edit5uYwiqcT9GMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/edit.vue?macro=true";
import { default as indexqcGY6BVULGMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/index.vue?macro=true";
import { default as money_45movementsix0zdNrEE4Meta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/money-movements.vue?macro=true";
import { default as notificationsHGmQ5wv2WeMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/notifications.vue?macro=true";
import { default as _91id_93X0LVqJJLeaMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/[id].vue?macro=true";
import { default as indexTysBv8uF8rMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/index.vue?macro=true";
import { default as placesyHBmJttDSnMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/places.vue?macro=true";
import { default as wishlistFoY7cDObjIMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/wishlist.vue?macro=true";
import { default as index0RXsscOD3hMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index.vue?macro=true";
import { default as offersd0foMcW1sVMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/offers.vue?macro=true";
import { default as privacyvUPCC4S5A2Meta } from "/opt/atlassian/pipelines/agent/build/pages/platform/privacy.vue?macro=true";
import { default as store_45typesWVhDViqfZ8Meta } from "/opt/atlassian/pipelines/agent/build/pages/platform/store-types.vue?macro=true";
import { default as _91id_932zkkMrUKS2Meta } from "/opt/atlassian/pipelines/agent/build/pages/platform/stores/[id].vue?macro=true";
import { default as indexEfMRkUkWzRMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/stores/index.vue?macro=true";
import { default as successful_45purchase_45cartyaXeRl0MdEMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/successful-purchase-cart.vue?macro=true";
import { default as termsrdlUaz4WfbMeta } from "/opt/atlassian/pipelines/agent/build/pages/platform/terms.vue?macro=true";
export default [
  {
    name: "domain-about-id___en",
    path: "/en/domain/about/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/about/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-about-id___tr",
    path: "/tr/domain/about/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/about/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-about-id___ar",
    path: "/domain/about/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/about/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-cart___en",
    path: "/en/domain/cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/cart.vue").then(m => m.default || m)
  },
  {
    name: "domain-cart___tr",
    path: "/tr/domain/cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/cart.vue").then(m => m.default || m)
  },
  {
    name: "domain-cart___ar",
    path: "/domain/cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/cart.vue").then(m => m.default || m)
  },
  {
    name: "domain-categories___en",
    path: "/en/domain/categories",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/categories.vue").then(m => m.default || m)
  },
  {
    name: "domain-categories___tr",
    path: "/tr/domain/categories",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/categories.vue").then(m => m.default || m)
  },
  {
    name: "domain-categories___ar",
    path: "/domain/categories",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/categories.vue").then(m => m.default || m)
  },
  {
    name: "domain___en",
    path: "/en/domain",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/index.vue").then(m => m.default || m)
  },
  {
    name: "domain___tr",
    path: "/tr/domain",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/index.vue").then(m => m.default || m)
  },
  {
    name: "domain___ar",
    path: "/domain",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-materials-id___en",
    path: "/en/domain/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-materials-id___tr",
    path: "/tr/domain/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-materials-id___ar",
    path: "/domain/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-materials___en",
    path: "/en/domain/materials",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-materials___tr",
    path: "/tr/domain/materials",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-materials___ar",
    path: "/domain/materials",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-offers___en",
    path: "/en/domain/offers",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/offers.vue").then(m => m.default || m)
  },
  {
    name: "domain-offers___tr",
    path: "/tr/domain/offers",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/offers.vue").then(m => m.default || m)
  },
  {
    name: "domain-offers___ar",
    path: "/domain/offers",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/domain/offers.vue").then(m => m.default || m)
  },
  {
    name: "platform-about-us___en",
    path: "/en/platform/about-us",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/about-us.vue").then(m => m.default || m)
  },
  {
    name: "platform-about-us___tr",
    path: "/tr/platform/about-us",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/about-us.vue").then(m => m.default || m)
  },
  {
    name: "platform-about-us___ar",
    path: "/platform/about-us",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/about-us.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth___en",
    path: "/en/platform/auth",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: "platform-auth-index-email-reset-password___en",
    path: "email-reset-password",
    meta: email_45reset_45passwordOEAbhh4mZMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/email-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-forgot___en",
    path: "forgot",
    meta: forgot5zCEC2nkwYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/forgot.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-login___en",
    path: "login",
    meta: loginE8pgO8gXgqMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-phone-reset-password___en",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordiSI0caNjS6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/phone-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-register___en",
    path: "register",
    meta: registeryG2D0wQyoZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/register.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "platform-auth___tr",
    path: "/tr/platform/auth",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: "platform-auth-index-email-reset-password___tr",
    path: "email-reset-password",
    meta: email_45reset_45passwordOEAbhh4mZMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/email-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-forgot___tr",
    path: "forgot",
    meta: forgot5zCEC2nkwYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/forgot.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-login___tr",
    path: "login",
    meta: loginE8pgO8gXgqMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-phone-reset-password___tr",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordiSI0caNjS6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/phone-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-register___tr",
    path: "register",
    meta: registeryG2D0wQyoZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/register.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "platform-auth___ar",
    path: "/platform/auth",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: "platform-auth-index-email-reset-password___ar",
    path: "email-reset-password",
    meta: email_45reset_45passwordOEAbhh4mZMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/email-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-forgot___ar",
    path: "forgot",
    meta: forgot5zCEC2nkwYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/forgot.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-login___ar",
    path: "login",
    meta: loginE8pgO8gXgqMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-phone-reset-password___ar",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordiSI0caNjS6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/phone-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-index-register___ar",
    path: "register",
    meta: registeryG2D0wQyoZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/index/register.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "platform-auth-social-_provider___en",
    path: "/en/platform/auth/social/_provider",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/social/_provider.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-social-_provider___tr",
    path: "/tr/platform/auth/social/_provider",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/social/_provider.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-social-_provider___ar",
    path: "/platform/auth/social/_provider",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/social/_provider.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-verify-email___en",
    path: "/en/platform/auth/verify-email",
    meta: verify_45emailZknSiJ9JAjMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-verify-email___tr",
    path: "/tr/platform/auth/verify-email",
    meta: verify_45emailZknSiJ9JAjMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "platform-auth-verify-email___ar",
    path: "/platform/auth/verify-email",
    meta: verify_45emailZknSiJ9JAjMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "platform-cart___en",
    path: "/en/platform/cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/cart.vue").then(m => m.default || m)
  },
  {
    name: "platform-cart___tr",
    path: "/tr/platform/cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/cart.vue").then(m => m.default || m)
  },
  {
    name: "platform-cart___ar",
    path: "/platform/cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/cart.vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering___en",
    path: "/en/platform/delivering",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering___tr",
    path: "/tr/platform/delivering",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering___ar",
    path: "/platform/delivering",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-materials-id___en",
    path: "/en/platform/delivering/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-materials-id___tr",
    path: "/tr/platform/delivering/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-materials-id___ar",
    path: "/platform/delivering/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-stores-id___en",
    path: "/en/platform/delivering/stores/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-stores-id___tr",
    path: "/tr/platform/delivering/stores/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-stores-id___ar",
    path: "/platform/delivering/stores/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-storeTypes-id___en",
    path: "/en/platform/delivering/storeTypes/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/storeTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-storeTypes-id___tr",
    path: "/tr/platform/delivering/storeTypes/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/storeTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-delivering-storeTypes-id___ar",
    path: "/platform/delivering/storeTypes/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/delivering/storeTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform___en",
    path: "/en/platform",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: "platform___tr",
    path: "/tr/platform",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: "platform___ar",
    path: "/platform",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-materials-id___en",
    path: "/en/platform/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-materials-id___tr",
    path: "/tr/platform/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-materials-id___ar",
    path: "/platform/materials/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-materials___en",
    path: "/en/platform/materials",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-materials___tr",
    path: "/tr/platform/materials",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-materials___ar",
    path: "/platform/materials",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index0RXsscOD3hMeta?.name,
    path: "/en/platform/my-account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index.vue").then(m => m.default || m),
    children: [
  {
    name: "platform-my-account-index-edit___en",
    path: "edit",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/edit.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index___en",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-money-movements___en",
    path: "money-movements",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/money-movements.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-notifications___en",
    path: "notifications",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/notifications.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-orders-id___en",
    path: "orders/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-orders___en",
    path: "orders",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-places___en",
    path: "places",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/places.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-wishlist___en",
    path: "wishlist",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0RXsscOD3hMeta?.name,
    path: "/tr/platform/my-account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index.vue").then(m => m.default || m),
    children: [
  {
    name: "platform-my-account-index-edit___tr",
    path: "edit",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/edit.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index___tr",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-money-movements___tr",
    path: "money-movements",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/money-movements.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-notifications___tr",
    path: "notifications",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/notifications.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-orders-id___tr",
    path: "orders/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-orders___tr",
    path: "orders",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-places___tr",
    path: "places",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/places.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-wishlist___tr",
    path: "wishlist",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0RXsscOD3hMeta?.name,
    path: "/platform/my-account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index.vue").then(m => m.default || m),
    children: [
  {
    name: "platform-my-account-index-edit___ar",
    path: "edit",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/edit.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index___ar",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-money-movements___ar",
    path: "money-movements",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/money-movements.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-notifications___ar",
    path: "notifications",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/notifications.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-orders___ar",
    path: "orders",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-places___ar",
    path: "places",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/places.vue").then(m => m.default || m)
  },
  {
    name: "platform-my-account-index-wishlist___ar",
    path: "wishlist",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/my-account/index/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "platform-offers___en",
    path: "/en/platform/offers",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/offers.vue").then(m => m.default || m)
  },
  {
    name: "platform-offers___tr",
    path: "/tr/platform/offers",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/offers.vue").then(m => m.default || m)
  },
  {
    name: "platform-offers___ar",
    path: "/platform/offers",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/offers.vue").then(m => m.default || m)
  },
  {
    name: "platform-privacy___en",
    path: "/en/platform/privacy",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/privacy.vue").then(m => m.default || m)
  },
  {
    name: "platform-privacy___tr",
    path: "/tr/platform/privacy",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/privacy.vue").then(m => m.default || m)
  },
  {
    name: "platform-privacy___ar",
    path: "/platform/privacy",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/privacy.vue").then(m => m.default || m)
  },
  {
    name: "platform-store-types___en",
    path: "/en/platform/store-types",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/store-types.vue").then(m => m.default || m)
  },
  {
    name: "platform-store-types___tr",
    path: "/tr/platform/store-types",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/store-types.vue").then(m => m.default || m)
  },
  {
    name: "platform-store-types___ar",
    path: "/platform/store-types",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/store-types.vue").then(m => m.default || m)
  },
  {
    name: "platform-stores-id___en",
    path: "/en/platform/stores/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-stores-id___tr",
    path: "/tr/platform/stores/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-stores-id___ar",
    path: "/platform/stores/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: "platform-stores___en",
    path: "/en/platform/stores",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-stores___tr",
    path: "/tr/platform/stores",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-stores___ar",
    path: "/platform/stores",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "platform-successful-purchase-cart___en",
    path: "/en/platform/successful-purchase-cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/successful-purchase-cart.vue").then(m => m.default || m)
  },
  {
    name: "platform-successful-purchase-cart___tr",
    path: "/tr/platform/successful-purchase-cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/successful-purchase-cart.vue").then(m => m.default || m)
  },
  {
    name: "platform-successful-purchase-cart___ar",
    path: "/platform/successful-purchase-cart",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/successful-purchase-cart.vue").then(m => m.default || m)
  },
  {
    name: "platform-terms___en",
    path: "/en/platform/terms",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/terms.vue").then(m => m.default || m)
  },
  {
    name: "platform-terms___tr",
    path: "/tr/platform/terms",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/terms.vue").then(m => m.default || m)
  },
  {
    name: "platform-terms___ar",
    path: "/platform/terms",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/platform/terms.vue").then(m => m.default || m)
  }
]